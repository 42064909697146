import { faStore, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { View, Image, Pressable, Modal } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { GetMissionsFromMapDto, MissionsClient } from '../../../api/api.g';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import Label from '../Label';
import { useQuery } from '@tanstack/react-query';
import useApi from '../../../hooks/api-hook';
import * as Location from 'expo-location';
import { MapLocation } from '../../../models/MapModels';
import { useAppSelector } from '../../../hooks/store-hook';
import Constants from 'expo-constants';
import Loading from '../Loading';
import Overlay from '../Overlay';
import Popup from '../Popup';

export interface OnARollPopupProps {
  onClose?(): void;
  onMissionSelect?(missions: GetMissionsFromMapDto): void;
}

const OnARollPopup = (props: OnARollPopupProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const apiMissions = useApi(MissionsClient);
  const authStore = useAppSelector((selector) => selector.auth);
  const defaultPosition: MapLocation = Constants.expoConfig.extra.defautlLocation;
  const [missions, setMissions] = useState<GetMissionsFromMapDto[]>();
  const [currentLocation, setCurrentLocation] = useState<MapLocation>(undefined);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const defaultLogo = useMemo(
    () => (
      <FontAwesomeIcon
        icon={faStore}
        style={{
          padding: 7,
          color: theme.text.titleLight.toString(),
          width: '100%',
          height: '100%',
        }}
      />
    ),
    [theme]
  );

  useEffect(() => {
    Location.requestForegroundPermissionsAsync().then(
      (result) => {
        if (!result.granted) {
          setCurrentLocation({ lat: defaultPosition.lat, lng: defaultPosition.lng });
          return;
        }

        Location.getLastKnownPositionAsync({ maxAge: 300000 }).then((lastKnownLocation) => {
          if (lastKnownLocation) {
            setCurrentLocation({ lat: lastKnownLocation.coords.latitude, lng: lastKnownLocation.coords.longitude });
          } else {
            Location.getCurrentPositionAsync({
              accuracy: Location.Accuracy.Balanced,
              mayShowUserSettingsDialog: true,
              timeInterval: 10000,
            }).then((currentPosition) => {
              if (currentPosition) {
                setCurrentLocation({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude });
              }
            });
          }
        });
      },
      (rejected) => {
        setCurrentLocation({ lat: defaultPosition.lat, lng: defaultPosition.lng });
      }
    );
  }, []);

  const getLogo = (logoUrl) => {
    if (logoUrl) {
      return <Image style={{ width: '100%', height: '100%' }} source={{ uri: logoUrl }} />;
    } else {
      return defaultLogo;
    }
  };

  const handleOnClosePress = () => {
    setShowPopup(false);
    props.onClose && props.onClose();
  };

  const getBoundingBox = (centerLat, centerLon, distanceKm) => {
    const latDiff = distanceKm / 110.574; // Approximate degrees of latitude per kilometer
    const lonDiff = distanceKm / (111.32 * Math.cos((centerLat * Math.PI) / 180)); // Approximate degrees of longitude per kilometer

    const bottomRight = {
      lat: centerLat - latDiff,
      lng: centerLon + lonDiff,
    };

    const topLeft = {
      lat: centerLat + latDiff,
      lng: centerLon - lonDiff,
    };

    return { bottomRight, topLeft };
  };

  const mapQuery = useQuery({
    enabled: !!currentLocation?.lat && !!currentLocation?.lng && !apiMissions.isCancelled && !!authStore.idToken,
    queryKey: ['customMap', 'missions', currentLocation?.lat, currentLocation?.lng],
    queryFn: ({ signal }) => {
      console.log('Get missions...');

      setLoading(true);

      let boundingBox = getBoundingBox(currentLocation?.lat, currentLocation?.lng, 50);

      const promise = apiMissions.getMissionsFromMap(
        [],
        `${currentLocation.lat}&${currentLocation.lng}`,
        `${boundingBox.bottomRight.lat}&${boundingBox.bottomRight.lng}`,
        `${boundingBox.topLeft.lat}&${boundingBox.topLeft.lng}`,
        true,
        apiMissions.tokenSource?.token
      );

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMissions.tokenSource?.cancel('getMissionsForMap: Query was cancelled by React Query');
        apiMissions.onCancel();
      });

      return promise;
    },
  });

  useEffect(() => {
    if (!mapQuery.isLoading) {
      setLoading(false);
    }

    if (mapQuery.isSuccess) {
      if (mapQuery.data.missions.length == 0 && mapQuery.data.highOrderMissions.length == 0) {
        handleOnClosePress();
      }

      let missions = mapQuery.data.missions.concat(mapQuery.data.highOrderMissions);

      if (missions && missions.length > 0) {
        setMissions(missions.splice(0, missions.length > 3 ? 4 : missions.length));
        setShowPopup(true);
      } else {
        handleOnClosePress();
      }
    }
  }, [mapQuery.isSuccess, mapQuery.data, mapQuery.isLoading]);

  return (
    <Modal animationType="none" visible={showPopup} transparent={true}>
      <Overlay showOverLay={true} zIndex={3000} onPress={handleOnClosePress}>
        <Popup>
          <View style={{ flexGrow: 1, backgroundColor: '#fef1dd', maxWidth: 350, maxHeight: 600 }}>
            <Image source={require('../../../../assets/on-a-roll.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
            <Pressable style={{ position: 'absolute' as any, top: 20, right: 20 }} onPress={handleOnClosePress}>
              <FontAwesomeIcon icon={faXmark} size={30} color={theme.background.primary.toString()} />
            </Pressable>
            <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8 }}>
              <View
                style={{
                  margin: 'auto',
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingBottom: 22,
                  display: 'flex',
                  flexGrow: 1,
                  flexShrink: 1,
                  width: '100%',
                }}
              >
                <Label style={{ fontSize: 28, color: '#f5a523', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
                  {t('on-a-roll-title')}
                </Label>
                <Label style={{ marginTop: 12, fontSize: 18, color: '#8e183d', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
                  {t('on-a-roll-desc')}
                </Label>
                {!loading ? (
                  <ScrollView style={{ flexGrow: 1, flexShrink: 1, marginTop: 12 }}>
                    {missions?.map((x, i) => (
                      <Pressable
                        key={x.branchId}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 8,
                          borderTopWidth: i == 0 ? 1 : 0,
                          borderBottomWidth: 1,
                          borderColor: 'rgba(245, 165, 35, 0.2)',
                          paddingHorizontal: 20,
                          paddingVertical: 8,
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          setShowPopup(false);
                          props.onMissionSelect && props.onMissionSelect(x);
                        }}
                      >
                        <View
                          style={{
                            display: 'flex' as any,
                            flexDirection: 'row' as any,
                            alignItems: 'center' as any,
                            justifyContent: 'center' as any,
                            width: 38,
                            height: 38,
                            borderRadius: 19,
                            borderWidth: 1,
                            borderColor: 'rgba(245, 165, 35, 0.2)',
                            overflow: 'hidden' as any,
                            backgroundColor: theme.background.secondary,
                          }}
                        >
                          {getLogo(x.logoUrl)}
                        </View>
                        <View style={{ gap: 5 }}>
                          <Label style={{ fontSize: 14, fontWeight: '600', textTransform: 'uppercase' }}>{x.missionConfigurationNames[0]}</Label>
                          <Label style={{ fontSize: 11, color: '#035164', fontWeight: '600' }}>{x.name}</Label>
                        </View>
                      </Pressable>
                    ))}
                  </ScrollView>
                ) : (
                  <View
                    style={{
                      paddingVertical: 48,
                      marginBottom: -24,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Loading />
                  </View>
                )}
              </View>
            </View>
          </View>
        </Popup>
      </Overlay>
    </Modal>
  );
};

export default OnARollPopup;
