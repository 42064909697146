import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { View, Pressable, Image, useWindowDimensions } from 'react-native';
import Label from '../common/Label';
import useTranslation from '../../hooks/translation-hook';
import useCustomTheme from '../../hooks/theme-hook';
import CustomButton from '../common/CustomButton';
import { useAppSelector } from '../../hooks/store-hook';
import { getAuth, RecaptchaVerifier, multiFactor, PhoneAuthProvider, sendEmailVerification, PhoneMultiFactorGenerator } from 'firebase/auth';
import CustomTextInput, { InputTypeValidation, ValidationResult } from '../common/CustomTextInput';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import useApi from '../../hooks/api-hook';
import { MysteryShopperClient } from '../../api/api.g';
import ReauthenticateUserForm from './ReauthenticateUserForm';
import { userPrefsActions } from '../../store/userPrefsSlice';
import Loading from '../common/Loading';
import useUserPrefsService from '../../hooks/userPrefsService-hook';
import { UserPrefsHelper } from '../../models/helpers/userPrefsHelper';

export interface AccountVerificationPopupProps {
  onClose?(): void;
  changingPhoneNumber?: boolean;
  emailVerified?: boolean;
}

const AccountVerificationPopup = (props: AccountVerificationPopupProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const auth = getAuth();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const authState = useAppSelector((selector) => selector.auth);
  const mysteryShopperApi = useApi(MysteryShopperClient);
  const dispatch = useDispatch();
  const userPrefService = useUserPrefsService();
  const layout = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [reloginError, setReloginError] = useState(false);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [invalideCode, setInvalideCode] = useState(false);
  const [showReauthenticateUser, setShowReauthenticateUser] = useState(false);
  const [resendEmailTimer, setResendEmailTimer] = useState(0);
  const [newPhone, setNewPhone] = useState(undefined);
  const [showNewPhone, setShowNewPhone] = useState(false);
  const [userPrefsValue, setUserPrefsValue] = useState(userPrefs);
  const [validation, setValidation] = useState<ValidationResult>();

  useEffect(() => {
    let interval = setInterval(() => {
      if (userPrefs.lastSendEmailTime) {
        const diff = new Date().getTime() - userPrefs.lastSendEmailTime.getTime();
        // round to seconds
        let nextResendEmailTimer = Math.round((120000 - diff) / 1000);
        if (nextResendEmailTimer <= 0) {
          clearInterval(interval);
        }
        setResendEmailTimer(nextResendEmailTimer);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [userPrefs.lastSendEmailTime]);

  const verifyPhoneNumber = useMutation({
    mutationFn: (phoneNumber: string) => {
      setIsLoading(true);
      return mysteryShopperApi
        .verifyPhoneNumber(phoneNumber, mysteryShopperApi.tokenSource?.token)
        .then((response) => {
          if (response) {
            setIsLoading(false);
            setValidation({ valid: false, message: t('PhoneNumber-used') });
          } else {
            setShowNewPhone(false);
            sendCode();
            setShowVerifyPhone(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setValidation({ valid: false, message: "Error: Couldn't verify phone number." });
        });
    },
  });

  useEffect(() => {
    let interval;

    if (props.emailVerified || (!props.changingPhoneNumber && authState.user.emailVerified && (showIntro || showVerifyEmail))) {
      setShowIntro(false);
      setShowVerifyEmail(false);
      setEmailVerified(true);
    }

    if (showVerifyEmail) {
      interval = setInterval(async () => {
        if (authState.user) {
          await authState.user.reload();
          const updatedUser = auth.currentUser;
          console.log('updatedUser', updatedUser, showVerifyEmail);

          if (updatedUser.emailVerified) {
            setShowIntro(false);
            setShowVerifyEmail(false);
            setEmailVerified(true);
            clearInterval(interval);
          }
        }
      }, 30000); // Check every minute
    }

    // Cleanup subscription and interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, [authState.user, showVerifyEmail, showIntro]);

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(auth, '2fa-recaptcha-container-2fa-popup', {
        size: 'invisible',
        callback: (response) => {},
      })
    );
  }, []);

  const verifyPhoneMutation = useMutation({
    mutationFn: () => {
      return mysteryShopperApi.verifyAccount();
    },
    onSuccess() {
      setShowVerifyPhone(false);
      setPhoneVerified(true);
      dispatch(userPrefsActions.setPhoneNumberVerified(true));
    },
  });

  const sendCode = () => {
    setIsLoading(true);

    multiFactor(auth.currentUser)
      .getSession()
      .then(function (multiFactorSession) {
        let phoneNumber = `+1${newPhone ?? userPrefs.phoneNumber}`;
        phoneNumber = phoneNumber.replace(/[ \-\(\)]/g, '');

        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then(function (verificationId) {
            setIsLoading(false);
            setVerificationId(verificationId);
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code == 'auth/requires-recent-login') {
              console.log('error', error);
              setShowVerifyPhone(false);
              setReloginError(true);
            }
          });
      });
  };

  const handleResendCode = () => {
    setCodeSent(true);
    sendCode();
  };

  const handleVerifyCode = () => {
    if (isLoading) return;

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    setIsLoading(true);

    if (props.changingPhoneNumber) {
      const data = UserPrefsHelper.mapToSaveBasicInformationDto(userPrefsValue);
      mysteryShopperApi.saveBasicInformation(data).then(() => {
        userPrefService.updateUserProfileInfo((userPrefs) => {
          const mfa = multiFactor(auth.currentUser);
          if (mfa?.enrolledFactors[0]) {
            mfa.unenroll(mfa.enrolledFactors[0]).then(() => {
              mfa
                .enroll(multiFactorAssertion, 'User Phone Number')
                .then(() => {
                  setShowVerifyPhone(false);
                  setPhoneVerified(true);
                })
                .catch((error) => {
                  setInvalideCode(true);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            });
          } else {
            mfa
              .enroll(multiFactorAssertion, 'User Phone Number')
              .then(() => {
                setShowVerifyPhone(false);
                setPhoneVerified(true);
              })
              .catch((error) => {
                setInvalideCode(true);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        });
      });
    } else {
      setInvalideCode(false);
      multiFactor(auth.currentUser)
        .enroll(multiFactorAssertion, 'User Phone Number')
        .then(() => {
          verifyPhoneMutation.mutate();
        })
        .catch((error) => {
          setInvalideCode(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleOnClosePress = () => {
    props.onClose && props.onClose();
  };

  const handleResendEmailVerification = () => {
    dispatch(userPrefsActions.setLastSendEmailTime(new Date()));

    sendEmailVerification(auth.currentUser).then(() => {
      setEmailSent(true);
    });
  };

  const handleStartVerification = () => {
    if (authState.user.emailVerified) {
      setShowIntro(false);

      if (props.changingPhoneNumber) {
        setShowReauthenticateUser(true);
      } else {
        setEmailVerified(true);
      }
    } else {
      dispatch(userPrefsActions.setLastSendEmailTime(new Date()));
      sendEmailVerification(auth.currentUser);
      setShowIntro(false);
      setShowVerifyEmail(true);
    }
  };

  const handleReauthenticateUser = () => {
    if (userPrefs.phoneNumber) {
      setEmailVerified(false);
      setReloginError(true);
    } else {
      setEmailVerified(false);
      setPhoneRequired(true);
    }
  };

  const handleStartPhoneVerification = () => {
    console.log('handleStartPhoneVerification', userPrefs.phoneNumber);
    if (props.changingPhoneNumber) {
      setShowReauthenticateUser(false);
      setShowNewPhone(true);
    } else {
      setShowReauthenticateUser(false);
      sendCode();
      setShowVerifyPhone(true);
    }
  };

  return (
    <View style={{ backgroundColor: '#EAF8FF', width: 350, height: 465, paddingTop: 12 }}>
      <View id="2fa-recaptcha-container-2fa-popup"></View>
      {showIntro && (
        <Pressable style={{ position: 'absolute' as any, top: 20, right: 20 }} onPress={handleOnClosePress}>
          <FontAwesomeIcon icon={faXmark} size={30} color={theme.text.darkTurquoiseTwo.toString()} />
        </Pressable>
      )}
      {showIntro && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 38, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Secure-Your-Account')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {props.changingPhoneNumber ? t('We-need-to-verify-your-account-before-you-can-chan') : t('Lets-verify-your-email-and-enable-two-factor-authe')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Lets-Do-It')}
            onPress={handleStartVerification}
          />
        </View>
      )}
      {showVerifyEmail && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Verify-Your-Email')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('We-have-sent-a-verification-email-to')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {authState.user.email}
            </Label>
          </View>
          <CustomButton
            disabled={resendEmailTimer > 0}
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: emailSent ? 0.5 : 1 }}
            textStyle={{ color: 'white' }}
            title={emailSent ? t('Email-sent') : `${t('Resend-Email')}: ${resendEmailTimer}s`}
            onPress={handleResendEmailVerification}
          />
        </View>
      )}
      {emailVerified && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Your-email-has-been-verified')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Now-lets-enable-two-factor-authentication-2FA-for')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('continue')}
            onPress={handleReauthenticateUser}
          />
        </View>
      )}
      {phoneRequired && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Phone-Number-Required')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Please-add-your-phone-number-in-the-profile-settin')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('close')}
            onPress={handleOnClosePress}
          />
        </View>
      )}
      {reloginError && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Reauthenticate')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('You-will-need-to-log-in-again-to-activate-the-two')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('continue')}
            onPress={() => {
              setReloginError(false);
              setShowReauthenticateUser(true);
            }}
          />
        </View>
      )}
      {showReauthenticateUser && <ReauthenticateUserForm onReauthenticate={handleStartPhoneVerification} />}
      {showNewPhone && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0, marginBottom: 16 }} numberOfLines={3}>
              {t('Please-enter-your-new-phone-number')}
            </Label>
            <CustomTextInput
              placeholder={t('your_cellphone_number')}
              required
              minLength={14}
              maxLength={14}
              validateOnBlur
              value={newPhone}
              inputTypeValidation={InputTypeValidation.phone}
              onChangeText={(t) => {
                setNewPhone(t);
                setUserPrefsValue((current) => {
                  return { ...current, phoneNumber: t };
                });
              }}
              onValidationChange={(validation) => {
                setValidation(validation);
              }}
              returnKeyType="done"
              onSubmitEditing={() => {
                verifyPhoneNumber.mutate(userPrefsValue.phoneNumber);
              }}
            />
            {validation?.valid === false && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{validation.message}</Label>}
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('continue')}
            onPress={() => {
              verifyPhoneNumber.mutate(userPrefsValue.phoneNumber);
            }}
          />
        </View>
      )}
      {showVerifyPhone && (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
              <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
                <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
                  {t('Enter-code')}
                </Label>
                <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
                  {t('We-have-sent-a-verification-code-to')}
                </Label>
                <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
                  {`+1 ${newPhone ?? userPrefs.phoneNumber}`}
                </Label>
              </View>
              <CustomTextInput
                style={{ marginTop: 24 }}
                placeholder={t('Verification-Code')}
                onChange={(e) => {
                  setVerificationCode(e.nativeEvent.text);
                }}
                value={verificationCode}
                returnKeyType="done"
                onSubmitEditing={() => {
                  if (verificationCode.length == 6) {
                    handleVerifyCode;
                  }
                }}
              />
              {invalideCode && (
                <Label style={{ marginTop: 12, fontSize: 18, color: 'red', fontWeight: '400', width: '100%', flexShrink: 0 }}>
                  {t('Invalid-verification-code')}
                </Label>
              )}
              {verificationCode.length == 6 ? (
                <CustomButton
                  style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: isLoading ? 0.5 : 1 }}
                  textStyle={{ color: 'white' }}
                  title={t('Verify-Code')}
                  onPress={handleVerifyCode}
                />
              ) : (
                <CustomButton
                  style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD', opacity: codeSent ? 0.5 : 1 }}
                  textStyle={{ color: 'white' }}
                  title={codeSent ? t('Code-sent') : t('Resend-Code')}
                  onPress={handleResendCode}
                />
              )}
            </View>
          )}
        </>
      )}
      {phoneVerified && (
        <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Your-phone-number-has-been-verified')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {props.changingPhoneNumber ? t('Your-phone-number-has-been-successfully-changed') : t('You-have-successfully-enabled-two-factor-authentic')}
            </Label>
          </View>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
            textStyle={{ color: 'white' }}
            title={t('Done')}
            onPress={() => {
              props.onClose && props.onClose();
            }}
          />
        </View>
      )}
    </View>
  );
};

export default AccountVerificationPopup;
