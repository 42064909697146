import React, { useCallback, useEffect, useState } from 'react';
import useTranslation from '../../../hooks/translation-hook';
import NavigationElements from '../../../models/NavigationElements';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import SettingsOptions from '../../common/SettingsOptions';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { AuthenticateClient, DeviceTheme } from '../../../api/api.g';
import { userPrefsActions } from '../../../store/userPrefsSlice';
import useMyAccountActions from '../../../hooks/myAccountActions-hook';
import useUserPrefsService from '../../../hooks/userPrefsService-hook';
import { LoggedState } from '../../../models/AuthModelsCommon';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import ProfileModal from '../myprofile/ProfileModal';
import { View } from 'react-native';
import CustomTextInput from '../../common/CustomTextInput';
import Label, { LabelType } from '../../common/Label';
import { authActions } from '../../../models/helpers/platformImportHelper';
import SettingsDeleteAccount from './SettingsDeleteAccount';
import useCustomTheme from '../../../hooks/theme-hook';
import { useNavigation } from '@react-navigation/native';
import useApi from '../../../hooks/api-hook';
import Loading from '../../common/Loading';

const Settings = () => {
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const userPerfsService = useUserPrefsService();
  const myAccountActions = useMyAccountActions();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const authStore = useAppSelector((selector) => selector.auth);
  const theme = useCustomTheme();
  const navigation = useNavigation();
  const apiAuth = useApi(AuthenticateClient);

  const [darkThemeActive, setDarkThemeActive] = useState(userPrefs.theme === DeviceTheme.Dark);
  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [loadingEmailChange, setLoadingEmailChange] = useState(false);

  const HandleChangeThemeCallback = useCallback(
    (e: boolean) => {
      let nextTheme = DeviceTheme.Light;

      if (e) {
        nextTheme = DeviceTheme.Dark;
        setDarkThemeActive(true);
      } else {
        setDarkThemeActive(false);
      }

      if (nextTheme !== userPrefs.theme) {
        // Updating the slice
        dispatch(userPrefsActions.changeTheme(nextTheme));
      }
    },
    [userPrefs.theme]
  );

  const handleSetLanguage = () => {
    myAccountActions.navigate(NavigationElements.settingsLanguage);
  };

  const handleSetNotifications = () => {
    myAccountActions.navigate(NavigationElements.settingsNotifications);
  };

  useEffect(() => {
    if (authStore?.user) {
      setEmail(authStore.user.email);
    }
  }, [authStore?.user?.email]);

  useEffect(() => {
    userPerfsService.saveBasicInfo();
  }, [userPrefs.theme]);

  const validateEmail = () => {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    var isValidEmail: boolean;

    isValidEmail = regexp.test(email);

    if (isValidEmail) {
      setEmailError(undefined);
      return true;
    } else {
      setEmailError(t('Invalid-email-address'));
      return false;
    }
  };

  const validatePassword = () => {
    setPasswordError(undefined);
    setConfirmPasswordError(undefined);

    if (password == '') {
      setPasswordError(t('login.password_required'));
      return false;
    }
    if (confirmPassword == '') {
      setConfirmPasswordError(t('login.password_required'));
      return false;
    }
    if (password && confirmPassword && password != confirmPassword) {
      setConfirmPasswordError(t('passwords_must_match_error'));
      return false;
    }

    return true;
  };

  const getChangeEmailModal = () => {
    return (
      <ProfileModal
        width={340}
        title={t('change_email')}
        hideConfirm={loadingEmailChange}
        onConfirm={() => {
          if (validateEmail()) {
            setLoadingEmailChange(true);
            dispatch(
              authActions.changeEmail({
                email: email,
                callback: (e) => {
                  if (e === true) {
                    dispatch(lightBoxActions.setVisibility(false));
                    setLoadingEmailChange(false);
                    myAccountActions.setVisibility(false);
                    navigation.navigate({ name: 'logout', params: { changeEmail: true } } as never);
                  } else {
                    setLoadingEmailChange(false);
                    if (e === 'auth/email-already-in-use') {
                      console.log('error email used');
                      setEmailError(t('login.email_in_use'));
                    }
                    if (e === 'auth/requires-recent-login') {
                      console.log('Error', e);
                      setEmailError(t('recent-login-required'));
                    }
                  }
                },
              })
            );
          }
        }}
      >
        <>
          {!loadingEmailChange && (
            <View>
              <Label style={{ marginBottom: 8 }}>{t('new_email')}</Label>
              <CustomTextInput
                onChange={(e) => {
                  setEmail(e.nativeEvent.text);
                }}
                onBlur={(e) => validateEmail()}
                value={email}
              />
              {emailError && (
                <Label variant={LabelType.body1} style={{ color: theme.colorErrorPrimary }}>
                  {emailError}
                </Label>
              )}
            </View>
          )}
          {loadingEmailChange && <Loading />}
        </>
      </ProfileModal>
    );
  };

  const getChangePasswordModal = () => {
    return (
      <ProfileModal
        width={340}
        title={t('change_password')}
        onConfirm={() => {
          if (validatePassword())
            dispatch(
              authActions.changePassword({
                password: password,
                callback: (e) => {
                  if (e === true) {
                    setPassword('');
                    setConfirmPassword('');
                    dispatch(lightBoxActions.setVisibility(false));
                  } else if (e === 'auth/weak-password') {
                    setPasswordError(t('login.weak_password'));
                  } else if (e === 'auth/requires-recent-login') {
                    setPasswordError(t('login.requires-recent-login'));
                  } else {
                    setPasswordError(e);
                  }
                },
              })
            );
        }}
      >
        <View>
          <Label style={{ marginBottom: 8 }}>{t('New-password')}</Label>
          <CustomTextInput
            secureTextEntry
            onChange={(e) => {
              setPassword(e.nativeEvent.text);
            }}
            onBlur={(e) => validatePassword()}
            value={password}
          />
          {passwordError && (
            <Label variant={LabelType.validationError} numberOfLines={5}>
              {passwordError}
            </Label>
          )}
          <Label style={{ marginTop: 16, marginBottom: 8 }}>{t('Confirm-password')}</Label>
          <CustomTextInput
            secureTextEntry
            onChange={(e) => {
              setConfirmPassword(e.nativeEvent.text);
            }}
            onBlur={(e) => validatePassword()}
            value={confirmPassword}
          />
          {confirmPasswordError && <Label variant={LabelType.validationError}>{confirmPasswordError}</Label>}
        </View>
      </ProfileModal>
    );
  };

  useEffect(() => {
    dispatch(lightBoxActions.setContent(getChangePasswordModal()));
  }, [password, confirmPassword, confirmPasswordError, passwordError]);

  useEffect(() => {
    dispatch(lightBoxActions.setContent(getChangeEmailModal()));
  }, [email, emailError, loadingEmailChange]);

  const changePassword = () => {
    dispatch(lightBoxActions.setContent(getChangePasswordModal()));
    dispatch(lightBoxActions.setVisibility(true));
  };

  const changeEmail = () => {
    dispatch(lightBoxActions.setContent(getChangeEmailModal()));
    dispatch(lightBoxActions.setVisibility(true));
  };

  return (
    <MyAccountPageWrapper
      navigationTitle={t('my_profile')}
      title={t('settings')}
      navigationAction={NavigationElements.menu}
      titleStyle={{ textTransform: 'none' }}
      footerElement={authStore.logged === LoggedState.Logged ? <SettingsDeleteAccount /> : undefined}
    >
      {authStore.logged !== LoggedState.Anonymous && <SettingsOptions bottomBorder title={t('email')} option={t('change_email')} onPress={changeEmail} />}
      {authStore.logged !== LoggedState.Anonymous && (
        <SettingsOptions bottomBorder title={t('password')} option={t('change_password')} onPress={changePassword} />
      )}
      <SettingsOptions bottomBorder title={t('language')} option={t('english_french')} onPress={handleSetLanguage} />
      {/* <SettingsOptions onOff title={t('appearance')} option={t('enable_dark_mode')} onPress={(e: any) => HandleChangeThemeCallback(e)} isEnabled={darkThemeActive} /> */}
      <SettingsOptions title={t('Notifcations')} option={t('Manage-desired-notifications')} onPress={handleSetNotifications} />
      {/* <Pressable
        style={{ paddingVertical: 16 }}
        onPress={() => {
          sharedPopup.showAccountVerificationPopup();
        }}
      >
        <Label style={{ fontSize: 16, color: theme.text.darkTurquoiseTwo, fontWeight: '600' }}>{t('Setup 2FA')}</Label>
      </Pressable> */}
    </MyAccountPageWrapper>
  );
};

export default Settings;
