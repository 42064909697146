import React, { useEffect, useState } from 'react';
import { Platform, Pressable, ScrollView, View } from 'react-native';
import CustomTextInput from '../components/common/CustomTextInput';
import Label, { LabelType } from '../components/common/Label';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { authActions } from '../models/helpers/platformImportHelper';
import CustomButton from '../components/common/CustomButton';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';
import useTranslation from '../hooks/translation-hook';
import { topBarActions } from '../store/topBarSlice';
import { userPrefsActions } from '../store/userPrefsSlice';
import Loading from '../components/common/Loading';

const UserActionReturn = ({ route }) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const t = useTranslation();
  const tLogin = useTranslation({ defaultPath: 'login' });
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  const [oobCode] = useState(route?.params?.oobCode);
  const [mode] = useState(route?.params?.mode);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [actionTitle, setActionTitle] = useState('');
  const [languageComponent, setLanguageComponent] = useState(<></>);

  const [formPasswordTouched, setFormPasswordTouched] = useState(false);
  const [formPasswordConfirmTouched, setFormPasswordConfirmTouched] = useState(false);
  const [passwordRegisterValidationError, setPasswordRegisterValidationError] = useState<string | undefined>(undefined);
  const [passwordRegisterConfValidationError, setPasswordRegisterConfValidationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (formPasswordTouched && formPasswordConfirmTouched && confirmPassword !== password) {
      setPasswordRegisterConfValidationError(t('must_confirm_password'));
    } else {
      setPasswordRegisterConfValidationError(undefined);
    }
  }, [confirmPassword, formPasswordConfirmTouched]);

  useEffect(() => {
    if (mode === 'resetPassword') {
      if (!oobCode) {
        navigation.navigate('login' as never);
      }

      dispatch(topBarActions.setVisibility(false));
      setActionTitle(tLogin('reset_password'));
    } else if (mode === 'verifyAndChangeEmail') {
      dispatch(
        authActions.applyActionCode({
          oobCode,
          callback: () => {
            navigation.navigate({ name: 'login', params: { emailChanged: true } } as never);
          },
        })
      );
    } else if (mode === 'verifyEmail') {
      dispatch(userPrefsActions.setShowEmailVerifiedPopup(true));
      dispatch(
        authActions.applyActionCode({
          oobCode,
          callback: () => {
            navigation.navigate({ name: 'login', params: { emailVerified: true } } as never);
          },
        })
      );
    } else if (mode === 'revertSecondFactorAddition') {
      dispatch(
        authActions.applyActionCode({
          oobCode,
          callback: () => {
            navigation.navigate({ name: 'login', params: { secondFactorRemoved: true } } as never);
          },
        })
      );
    }
  }, [mode, oobCode, userPrefs.uiLanguage]);

  useEffect(() => {
    setLanguageComponent(<View style={{ alignSelf: 'center', marginTop: 32 }}>{getOtherLanguageButton()}</View>);
  }, [userPrefs.uiLanguage]);

  const formIsValid = (): boolean => {
    return !passwordRegisterValidationError && !passwordRegisterConfValidationError;
  };

  const handleConfirmPasswordChange = () => {
    if (!formPasswordTouched || !formPasswordConfirmTouched || !formIsValid()) {
      if (!password || password.length == 0) {
        setPasswordRegisterValidationError(tLogin('password_required'));
        return;
      }
    }

    if (password) {
      // Dispatch the password change confirmation
      dispatch(
        authActions.confirmThePasswordReset({
          oobCode,
          newPassword: password,
          callback: (r) => {
            if (r.code) {
              // If there is a code, it's and error

              if (r.code == 'auth/invalid-action-code') {
                setPasswordRegisterValidationError(tLogin('invalid_action_code'));
                return;
              }

              if (r.code == 'auth/weak-password') {
                setPasswordRegisterValidationError(tLogin('weak_password'));
                return;
              }

              setPasswordRegisterValidationError(r.message);
            } else {
              navigation.navigate({ name: 'login', params: { reset: 1 } } as never);
            }
          },
        })
      );
    }
  };

  const handleConfirmButtonClick = () => {
    if (mode === 'resetPassword') {
      handleConfirmPasswordChange();
    }
  };

  const getOtherLanguageButton = () => {
    switch (userPrefs.uiLanguage) {
      case 'fr':
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguage('en'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>EN</Label>
          </Pressable>
        );
      default:
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguage('fr'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>FR</Label>
          </Pressable>
        );
    }
  };

  return (
    <LinearGradient end={{ x: 1.4, y: 0 }} style={{ height: '100%', width: '100%' }} colors={['#035164', '#43a49e']}>
      <ScrollView style={{ height: '100%', width: '100%' }}>
        <View style={{ height: '100%', width: '100%', padding: 16, marginTop: 44, alignItems: 'center' }}>
          {mode !== 'verifyAndChangeEmail' && mode !== 'verifyEmail' && (
            <View style={{ height: '100%', width: '100%', maxWidth: 400 }}>
              <Label
                variant={LabelType.titleLarge}
                style={{ color: 'white', flexDirection: 'row', fontWeight: '700', textAlign: 'center', alignItems: 'center', marginBottom: 32 }}
              >
                {actionTitle}
              </Label>
              <View style={{ marginBottom: 24 }}>
                <Label style={{ width: '100%', textAlign: 'center', marginBottom: 8 }} variant={LabelType.labelWhite}>
                  {t('create_your_password')}
                </Label>
                <CustomTextInput
                  placeholder={t('password')}
                  required
                  requiredMessage={tLogin('password_required')}
                  onValidationChange={(v) => (v.valid ? setPasswordRegisterValidationError(undefined) : setPasswordRegisterValidationError(v.message))}
                  secureTextEntry={true}
                  key={4}
                  value={password}
                  onChange={(e) => {
                    const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;

                    if ((text?.length ?? 0) > 0) {
                      setFormPasswordTouched(true);
                    }
                    setPassword(text);
                  }}
                  // onChangeText={(text) => {
                  //   if (text.length > 0) {
                  //     setFormPasswordTouched(true);
                  //   }
                  //   setPassword(text);
                  // }}
                  onBlur={(e) => {
                    if ((e.currentTarget as any).value.length > 0) {
                      setFormPasswordTouched(true);
                    }
                  }}
                  onKeyPress={(e) => {
                    if ((e as any).code === 'Enter') {
                      handleConfirmButtonClick();
                    }
                  }}
                />
                {passwordRegisterValidationError && <Label variant={LabelType.validationError}>{passwordRegisterValidationError}</Label>}
              </View>
              <View style={{ marginBottom: 24 }}>
                <Label style={{ width: '100%', textAlign: 'center', marginBottom: 8 }} variant={LabelType.labelWhite}>
                  {t('confirm_your_password')}
                </Label>
                <CustomTextInput
                  placeholder={t('password')}
                  required
                  requiredMessage={t('must_confirm_password')}
                  onValidationChange={(v) =>
                    v.valid
                      ? !passwordRegisterConfValidationError
                        ? setPasswordRegisterConfValidationError(undefined)
                        : false
                      : setPasswordRegisterConfValidationError(v.message)
                  }
                  secureTextEntry={true}
                  key={5}
                  value={confirmPassword}
                  onChange={(e) => {
                    const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                    setConfirmPassword(text);
                  }}
                  // onChangeText={(text) => {
                  //   setConfirmPassword(text);
                  // }}
                  onBlur={(e) => {
                    if ((e.currentTarget as any).value.length > 0) {
                      setFormPasswordConfirmTouched(true);
                    }
                  }}
                  onKeyPress={(e) => {
                    if ((e as any).code === 'Enter') {
                      handleConfirmButtonClick();
                    }
                  }}
                />
                {passwordRegisterConfValidationError && <Label variant={LabelType.validationError}>{passwordRegisterConfValidationError}</Label>}
              </View>
              <CustomButton
                style={{ marginTop: 24 }}
                title={t('change_password')}
                textStyle={{ textTransform: 'uppercase' }}
                onPress={handleConfirmButtonClick}
              />
              <CustomButton
                style={{ marginTop: 24 }}
                title={t('back')}
                textStyle={{ color: 'white', textTransform: 'uppercase' }}
                secondary={true}
                onPress={() => navigation.navigate('login' as never)}
              />
              {languageComponent}
            </View>
          )}
          {mode === 'verifyAndChangeEmail' && <Loading iconColor={'white'} textVariant={LabelType.loginLoading} text={t('email-change-loading')} />}
          {mode === 'verifyEmail' && <Loading iconColor={'white'} textVariant={LabelType.loginLoading} text={t('Verifying email')} />}
          {mode === 'revertSecondFactorAddition' && <Loading iconColor={'white'} textVariant={LabelType.loginLoading} text={t('revert_second_factor')} />}
        </View>
      </ScrollView>
    </LinearGradient>
  );
};

export default UserActionReturn;
