import React from 'react';
import { Platform, Keyboard, Pressable, PressableProps, View, KeyboardAvoidingView } from 'react-native';
import useStyles from '../../hooks/styles-hook';

export interface OverlayProps extends PressableProps {
  showOverLay: boolean;
  zIndex: number;
  children?: any;
}

const Overlay = (props: OverlayProps) => {
  const styles = useStyles((section) => section.overlay);

  const handleClick = (event) => {
    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
    }

    // Check if the click target is the element itself, not its child
    if (event.target === event.currentTarget) {
      props.onPress && props.onPress(event);
    }
  };

  return (
    <View style={[{ zIndex: props.zIndex, display: props.showOverLay ? 'flex' : 'none' }, styles.container]}>
      <Pressable style={styles.overlay} onPressIn={handleClick}></Pressable>
      <View
        style={{
          position: 'absolute',
          zIndex: 1,
          maxWidth: '100%',
          maxHeight: '100%',
          justifyContent: 'center' as any,
          alignItems: 'center' as any,
        }}
      >
        {props.children}
      </View>
    </View>
  );
};

export default Overlay;
