import React, { ReactElement } from 'react';
import { KeyboardAvoidingView, Platform, useWindowDimensions, View } from 'react-native';
import Loading from './Loading';
import useCustomTheme from '../../hooks/theme-hook';
import { ScrollView } from 'react-native-gesture-handler';

export interface PopupProps {
  loading?: boolean;
  children: ReactElement;
}

const Popup = (props: PopupProps) => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();

  return (
    <KeyboardAvoidingView
      style={{
        borderRadius: 8,
        backgroundColor: theme.background.modal,
        overflow: 'hidden',
        zIndex: 2000,
        maxHeight: layout.height - 40,
      }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <ScrollView style={{ flexGrow: 0 }} showsVerticalScrollIndicator={true}>
        {props.loading && <Loading style={{ width: '100%', height: '100%', backgroundColor: 'rgba(233, 245, 243, 0.8)', zIndex: 4000 }} />}
        {props.children}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default Popup;
