import React, { useEffect, useRef, useState } from 'react';
import { View, Pressable, Modal, Platform } from 'react-native';
import useTranslation from '../../../hooks/translation-hook';
import NavigationElements from '../../../models/NavigationElements';
import Label, { LabelType } from '../../common/Label';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import useCustomTheme from '../../../hooks/theme-hook';
import CustomButton from '../../common/CustomButton';
import useApi from '../../../hooks/api-hook';
import { CanCashOutStatusType, CancelCashOutStatusType, GetMyRewardsViewModel, MyRewardsClient, TransactionsClient } from '../../../api/api.g';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../../../hooks/store-hook';
import Loading from '../../common/Loading';
import useStyles from '../../../hooks/styles-hook';
import MyRewardsProgression from './MyRewardsProgression';
import { useDispatch } from 'react-redux';
import { myAccountActions } from '../../../store/myAccountSlice';
import { StringHelper } from '../../../helpers/stringHelper';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import CashoutPopup from './CashoutPopup';
import CustomMarkdown from '../../common/CustomMarkDown';
import CashoutSuccessPopup from './CashoutSuccessPopup';
import ProfileManager, { ProfileManagerHandles } from '../myprofile/ProfileManager';
import { UserPreferencesState } from '../../../store/userPrefsSlice';
import AccountVerificationPopup from '../AccountVerificationPopup';
import Overlay from '../../common/Overlay';
import Popup from '../../common/Popup';

const MyRewards = () => {
  const profileManager = useRef<ProfileManagerHandles>(null);

  const theme = useCustomTheme();
  const t = useTranslation();
  const dispatch = useDispatch();
  const authStore = useAppSelector((selector) => selector.auth);
  const accountStore = useAppSelector((selector) => selector.myAccount);
  const apiRewards = useApi(MyRewardsClient);
  const transactionApi = useApi(TransactionsClient);
  const styles = useStyles((selector) => selector.myRewards);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  const [rewards, setRewards] = useState<GetMyRewardsViewModel | undefined>();
  const [status, setStatus] = useState<CanCashOutStatusType>();
  const [cancelStatus, setCancelStatus] = useState<CancelCashOutStatusType>();
  const [transactionId, setTransactionId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [canCashOutSeed, setCanCashOutSeed] = useState<string>(StringHelper.generateRandomString());
  const [showVerificationPopup, setShowVerificationPopup] = useState<boolean>(false);

  const getMyRewards = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['myRewardsQuery', authStore.logged, authStore.idToken, accountStore.myRewardsSeed],
    queryFn: ({ signal }) => {
      const promise = apiRewards.getMyRewards(apiRewards.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiRewards.tokenSource?.cancel('Query was cancelled by React Query');
        apiRewards.onCancel();
      });

      return promise;
    },
    onError: (err) => {
      throw err;
    },
  });

  const canCashOutQuery = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['canCashOutQuery', canCashOutSeed],
    queryFn: () => {
      setStatus(undefined);
      return transactionApi.canProceedToCashOut(transactionApi.tokenSource?.token);
    },
  });

  const cashOutMutation = useMutation({
    mutationFn: () => {
      setLoading(true);
      return transactionApi.proceedToCashOut(transactionApi.tokenSource?.token);
    },
    onSuccess(data) {
      dispatch(lightBoxActions.setIsLoading(false));
      setLoading(false);
      dispatch(myAccountActions.setMyRewardsSeed(StringHelper.generateRandomString()));
      dispatch(lightBoxActions.setContent(<CashoutSuccessPopup />));

      if (data.status === CanCashOutStatusType.Succeeded) {
        setStatus(CanCashOutStatusType.Refresh);
      } else {
        setStatus(data.status);
      }

      setTransactionId(data.cashOutTransactionId);
    },
  });

  const cancelCashOutMutation = useMutation({
    mutationFn: () => {
      setLoading(true);
      return transactionApi.cancelCashOut(transactionId, transactionApi.tokenSource?.token);
    },
    onSuccess(data) {
      setLoading(false);
      dispatch(myAccountActions.setMyRewardsSeed(StringHelper.generateRandomString()));
      setCancelStatus(data.status);
    },
  });

  const verifyUserCanCashout = (userPrefs: UserPreferencesState) => {
    if (userPrefs.phoneNumberVerified) {
      if (
        profileManager.current.verifyUserCanCashOut(userPrefs, (pref) => {
          verifyUserCanCashout(pref);
        })
      ) {
        handleCashOut();
      }
    } else {
      setShowVerificationPopup(true);
    }
  };

  const handleCashOut = () => {
    dispatch(
      lightBoxActions.setContent(
        <CashoutPopup
          onConfirm={() => {
            cashOutMutation.mutate();
          }}
        />
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const handleCancel = () => {
    cancelCashOutMutation.mutate();
  };

  useEffect(() => {
    switch (cancelStatus) {
      case CancelCashOutStatusType.Succeeded:
        setMessage('');
        break;
      case CancelCashOutStatusType.NoTransactionId:
        setMessage(t('Something-went-wrong-Please-try-again-later'));
        break;
      case CancelCashOutStatusType.PaymentCancelFailed:
        setMessage(t('Something-went-wrong-Please-try-again-later'));
        break;
      case CancelCashOutStatusType.CashInFailed:
        setMessage(t('Something-went-wrong-Please-call-support'));
        break;
      case CancelCashOutStatusType.AlreadyCancelled:
        setCanCashOutSeed(StringHelper.generateRandomString());
        break;
    }
  }, [cancelStatus]);

  useEffect(() => {
    switch (status) {
      case CanCashOutStatusType.Succeeded:
        setMessage('');
        break;
      case CanCashOutStatusType.Failed:
        setMessage(t('Something-went-wrong-Please-call-support'));
        break;
      case CanCashOutStatusType.InProgress:
        setMessage(t('Cash-out-processing'));
        break;
      case CanCashOutStatusType.NoPoints:
        setMessage(t('You-dont-have-points-to-cash-out'));
        break;
      case CanCashOutStatusType.NotEnoughPoints:
        setMessage(t('You-dont-have-enought-points-to-cash-out'));
        break;
      case CanCashOutStatusType.Refresh:
        setTimeout(() => {
          setCanCashOutSeed(StringHelper.generateRandomString());
        }, 10000);
        break;
    }
  }, [status]);

  useEffect(() => {
    if (canCashOutQuery.isSuccess) {
      setStatus(canCashOutQuery.data.status);
      setTransactionId(canCashOutQuery.data.cashOutTransactionId);
      setLoading(false);
    } else if (canCashOutQuery.isError) {
      setLoading(false);
    }
  }, [canCashOutQuery.isSuccess, canCashOutQuery.data, canCashOutQuery.isError]);

  useEffect(() => {
    setRewards(getMyRewards.data);
  }, [getMyRewards.isSuccess, getMyRewards.data]);

  return (
    <MyAccountPageWrapper
      navigationTitle={t('my_profile')}
      title={t('my_rewards')}
      navigationAction={NavigationElements.menu}
      moreInfoComponent={{
        showTitle: true,
        externalLink: t('lanla_website_support'),
        title: t('help_support'),
        children: `## Rursus septem

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`,
      }}
    >
      <View>
        <Modal animationType="none" visible={showVerificationPopup} transparent={true}>
          <Overlay showOverLay={true} zIndex={3000}>
            <Popup>
              <AccountVerificationPopup
                onClose={() => {
                  setShowVerificationPopup(false);
                }}
              />
            </Popup>
          </Overlay>
        </Modal>
        {!getMyRewards.isLoading && (
          <>
            <View style={styles.cashoutContainer}>
              <View style={styles.cashoutSummary}>
                <View>
                  <Label style={styles.cashoutTotalPoints}>{t('currency-value', { value: rewards?.amountCurrency ?? '-' })}</Label>
                  <Label style={styles.cashoutTotalPointsLabel}>{t('points-value', { value: rewards?.amountPts ?? '-' })}</Label>
                </View>
                <View style={{ alignItems: 'center', marginTop: 16 }}>
                  {!loading ? (
                    <>
                      {message && message.length > 0 && (
                        <View
                          style={{
                            ...StylesHelper.padding(16),
                            width: '100%',
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                              gap: 16,
                              alignItems: 'center',
                              backgroundColor: theme.background.primaryLight,
                              ...StylesHelper.padding(12, 16),
                              ...StylesHelper.borderRadius(8),
                              flexGrow: 1,
                            }}
                          >
                            <FontAwesomeIcon style={{ flexShrink: 0 }} icon={faCircleExclamation} size={28} color={theme.text.error.toString()} />
                            <View style={{ flexShrink: 1 }}>
                              <Label style={{ fontSize: 14, color: theme.text.error, fontWeight: '600' }}>{message}</Label>
                            </View>
                          </View>
                        </View>
                      )}
                      <View style={{ flexDirection: 'row' }}>
                        <CustomButton
                          textStyle={{ textAlign: 'center' }}
                          title={t('cash_out_now')}
                          disabled={status != CanCashOutStatusType.Succeeded || (rewards && rewards.amountPts <= 0)}
                          onPress={() => {
                            verifyUserCanCashout(userPrefs);
                          }}
                        />
                      </View>
                      {status == CanCashOutStatusType.InProgress && (
                        <Pressable onPress={handleCancel}>
                          <Label style={{ textDecorationLine: 'underline' }}>{t('cancel_cash_out')}</Label>
                        </Pressable>
                      )}
                    </>
                  ) : (
                    <View style={{ alignItems: 'center', minHeight: 50 }}>
                      <Loading />
                    </View>
                  )}
                </View>
              </View>
              {false && (
                <View style={styles.cashoutValueProgress}>
                  <MyRewardsProgression barScale={rewards?.progression?.values} fillingPercentage={rewards?.progression?.percentageValue} />
                </View>
              )}
            </View>
            <View style={styles.pointsExplanation}>
              <Label style={styles.pointsExplanationTitle} variant={LabelType.titleSmall}>
                {t('points-dollars-conversion', { points: rewards?.pointsPerCurrency ?? '-' })}
              </Label>
              <CustomMarkdown>{t('cashout_explanation')}</CustomMarkdown>
            </View>
          </>
        )}
        {getMyRewards.isLoading && <Loading />}
        <ProfileManager ref={profileManager} />
      </View>
    </MyAccountPageWrapper>
  );
};

export default MyRewards;
