import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import useCustomTheme from '../../../hooks/theme-hook';
import NavigationButton from '../NavigationButton';
import Loading from '../Loading';
import useTranslation from '../../../hooks/translation-hook';
import { View, Image, Pressable, Platform, ScrollView, Linking, Modal } from 'react-native';
import useStyles from '../../../hooks/styles-hook';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  faAward,
  faBasketShopping,
  faBullseye,
  faCalendar,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faEyeSlash,
  faMap,
  faPenNib,
  faReceipt,
  faStore,
  faTriangleExclamation,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import Label, { LabelType } from '../Label';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import pinSmallBlue from '../../../../assets/pin-small-blue.png';
import pinSmallOrange from '../../../../assets/pin-small-orange.png';
import pinSmallGreen from '../../../../assets/pin-small-green.png';
import pinSmallGrey from '../../../../assets/pin-small-grey.png';
import CustomButton from '../CustomButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import useApi from '../../../hooks/api-hook';
import {
  AcceptMissionStatus,
  GetAcceptMissionByBranchDto,
  GetMissionDetailsDto,
  GetMissionsFromMapDto,
  GetMysteryShopperProfileMemberDto,
  MissionConfigurationDetailType,
  MissionsClient,
  MyMissionClient,
} from '../../../api/api.g';
import { useNavigation } from '@react-navigation/native';
import { MapLocation } from '../../../models/MapModels';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import { missionsActions } from '../../../store/missionsSlice';
import CustomMarkdown from '../CustomMarkDown';
import MoreInfoButton from '../MoreInfoButton';
import ModalContainer from '../ModalContainer';
import { StringHelper } from '../../../helpers/stringHelper';
import useAdvertisementTrackingHook from '../../../hooks/advertisement-hook';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { LoggedState } from '../../../models/AuthModelsCommon';
import LoginPopup from '../../login/LoginPopup';
import { drawerActions, DrawerOpenState } from '../../../store/drawerSlice';
import { UserPreferencesState } from '../../../store/userPrefsSlice';
import ComboPopup, { ComboMissionItem } from './MissionComboPopup';
import ProfileManager, { ProfileManagerHandles } from '../../myaccount/myprofile/ProfileManager';
import Overlay from '../Overlay';
import AccountVerificationPopup from '../../myaccount/AccountVerificationPopup';
import Popup from '../Popup';

export interface MissionBranchListProps {
  missionFromMap?: GetMissionsFromMapDto;
  location?: MapLocation;
  onBackPress?(): void;
  onMoreInfoPress?(info: string): void;
  onAdmissibilityPress?(info: string): void;
  onConditionsPress?(info: string): void;
}

export interface MissionDetailItem {
  dto: GetMissionDetailsDto;
  expanded: boolean;
}

const MissionBranchList = (props: MissionBranchListProps) => {
  const profileManager = useRef<ProfileManagerHandles>(null);
  const theme = useCustomTheme();
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const styles = useStyles((sector) => sector.missionDetailCard);
  const stylesCard = useStyles((section) => section.missionCard);
  const authStore = useAppSelector((selector) => selector.auth);
  const apiMyMission = useApi(MyMissionClient);
  const apiMissions = useApi(MissionsClient);
  const navigation = useNavigation();
  const advertisementTracking = useAdvertisementTrackingHook();

  const defaultLogo = useMemo(() => <FontAwesomeIcon icon={faStore} style={stylesCard.header.logo.icon} />, [stylesCard]);
  const [logo, setLogo] = useState<ReactNode>(defaultLogo);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(<></>);
  const [missionFromMap, setMissionFromMap] = useState<GetMissionsFromMapDto>();
  const [location, SetLocation] = useState<MapLocation>();
  const [missionDetails, setMissionDetails] = useState<MissionDetailItem[]>();
  const [acceptMissionViewModels, setAcceptMissionViewModels] = useState<GetAcceptMissionByBranchDto[]>([]);
  const [canAcceptMissionQuerySeed, setCanAcceptMissionQuerySeed] = useState<string>(StringHelper.generateRandomString());
  const [recheckCanAcceptMission, setRecheckCanAcceptMission] = useState<string>(undefined);
  const [acceptMissionQueue, setAcceptMissionQueue] = useState<GetMissionDetailsDto[]>([]);
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);

  const [showAnonymousPopup, setShowAnonymousPopup] = useState(<></>);

  useEffect(() => {
    setMissionFromMap(props.missionFromMap);
    setCanAcceptMissionQuerySeed(StringHelper.generateRandomString());
  }, [props.missionFromMap]);

  useEffect(() => {
    SetLocation(props.location);
  }, [props.location]);

  const branchDetailQuery = useQuery({
    enabled: !!missionFromMap?.branchId && !!location && !!authStore.idToken,
    queryKey: ['branchDetailQuery', missionFromMap?.branchId, location, userPrefs.uiLanguage],
    queryFn: () => {
      return apiMissions.getMissionDetails(missionFromMap?.branchId, `${location.lat}&${location.lng}`, false);
    },
  });

  useEffect(() => {
    if (branchDetailQuery.isSuccess) {
      // console.log('branchDetailQuery', branchDetailQuery.data);
      setMissionDetails(
        branchDetailQuery.data.missionDetails.map((x) => {
          return { dto: x, expanded: branchDetailQuery.data.missionDetails.length <= 3 };
        })
      );
    }
  }, [branchDetailQuery.isSuccess, branchDetailQuery.data]);

  useEffect(() => {
    if (missionFromMap?.address) {
      setAddress(<Label variant={LabelType.body1}>{missionFromMap.address}</Label>);
    } else {
      setAddress(<></>);
    }

    if (missionFromMap?.logoUrl && missionFromMap?.hasLogo) {
      setLogo(<Image style={stylesCard.header.logo.image} source={{ uri: missionFromMap.logoUrl }} />);
    } else {
      setLogo(defaultLogo);
    }
  }, [missionFromMap]);

  const handleOnBackPress = () => {
    if (props.onBackPress) {
      props.onBackPress();
    }
  };

  const canAcceptMissionQuery = useQuery({
    enabled: !!missionFromMap?.branchId && !!authStore.idToken,
    queryKey: ['canAcceptMissionQuery', missionFromMap?.branchId, canAcceptMissionQuerySeed],
    queryFn: () => {
      return apiMissions.getAcceptMissions(missionFromMap?.branchId, apiMissions.tokenSource?.token);
    },
    onSuccess(data) {
      setAcceptMissionViewModels(data.items);
    },
    onError: (err) => {
      setLoading(false);
      throw err;
    },
  });

  const acceptMission = useMutation({
    retry: false,
    mutationFn: (missionInfo: { periodMissionConfigurationId: string; branchId: string }) => {
      setAcceptLoading(true);
      return apiMissions.acceptMission(missionInfo.periodMissionConfigurationId, missionInfo.branchId, apiMyMission.tokenSource?.token);
    },
    onError: (err) => {
      setAcceptMissionQueue([]);
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(lightBoxActions.setIsLoading(false));
      setAcceptLoading(false);
      setLoading(false);
      throw err;
    },
  });

  useEffect(() => {
    if (acceptMission.isSuccess) {
      if (acceptMission.data.hardLimitReached) {
        dispatch(lightBoxActions.setIsLoading(false));
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 16, paddingVertical: 16 }}>
              <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('HardLimitReached')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      } else if (acceptMission.data.verifyUserPhoneNumber) {
        dispatch(lightBoxActions.setIsLoading(false));
        dispatch(lightBoxActions.setVisibility(false));
        setShowVerificationPopup(true);
        setAcceptLoading(false);
      } else {
        setAcceptMissionQueue((prev) => {
          prev = prev.filter((x) => x.periodMissionConfigurationId !== acceptMission.variables.periodMissionConfigurationId);

          if (prev.length === 0) {
            dispatch(lightBoxActions.setVisibility(false));
            dispatch(lightBoxActions.setIsLoading(false));
            setAcceptLoading(false);
            setLoading(false);

            if (acceptMission?.data?.success) {
              dispatch(drawerActions.setState(DrawerOpenState.closed));
              dispatch(missionsActions.setMissionAcceptedId(acceptMission.data.missionAssignationId));
              dispatch(missionsActions.setReloadMissionListSeed(String.prototype.generateRandomString(5)));
              dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
              navigation.navigate('mymissions' as never);
            } else {
              setAcceptMissionViewModels((prev) => {
                let missionStatus = prev.find((x) => x.periodMissionConfigurationId === acceptMission.variables.periodMissionConfigurationId);
                missionStatus.missingProfileMembers = acceptMission.data.missingProfileMembers;
                missionStatus.acceptMissionStatus = acceptMission.data.acceptMissionStatus;
                missionStatus.rotationInfo = acceptMission.data.rotationInfo;

                return prev.slice();
              });
            }
          } else {
            acceptMission.mutate({ branchId: missionFromMap.branchId, periodMissionConfigurationId: prev[0].periodMissionConfigurationId });
          }

          return prev;
        });
      }
    }
  }, [acceptMission.isSuccess, acceptMission.data]);

  const handleOnConfirm = (dto: GetMysteryShopperProfileMemberDto, onConfirm: any) => {
    profileManager.current.showProfileMemberPopup(
      {
        value: '',
        profileMember: dto,
      },
      () => {
        onConfirm();
      }
    );
  };

  const handleOnExplanationClose = (dontShowAgain) => {
    if (dontShowAgain) {
      advertisementTracking.dontShowAgain('ProfileRequirement');
    }
  };

  useEffect(() => {
    if (recheckCanAcceptMission && acceptMissionViewModels) {
      // if (!authStore.multifactor.signInSecondFactor)  {
      //   //sharedPopup.showAccountVerificationPopup();
      // } else {
      handleAcceptMission(recheckCanAcceptMission, missionFromMap.branchId);
      // }
    }
  }, [recheckCanAcceptMission, acceptMissionViewModels]);

  const verifyHaveUserBasicInfo = (userPrefs: UserPreferencesState, periodMissionConfigurationId) => {
    if (
      profileManager.current.verifyUserCanAcceptMission(userPrefs, (pref) => {
        console.log('callback');
        verifyHaveUserBasicInfo(pref, periodMissionConfigurationId);
      })
    ) {
      setAcceptMissionViewModels(undefined);
      setCanAcceptMissionQuerySeed(StringHelper.generateRandomString());
      setRecheckCanAcceptMission(periodMissionConfigurationId);
    }
  };

  const needToKnowMoreAboutUserPopup = (periodMissionConfigurationId) => {
    if (
      authStore.logged !== LoggedState.Anonymous &&
      (userPrefs.firstName === '' ||
        userPrefs.firstName === undefined ||
        userPrefs.lastName === '' ||
        userPrefs.lastName === undefined ||
        userPrefs.phoneNumber === undefined ||
        userPrefs.phoneNumber === '' ||
        userPrefs.profiles?.find((x) => x.key === 'BirthDate') === undefined ||
        userPrefs.profiles?.find((x) => x.key === 'Gender') === undefined ||
        userPrefs.profiles?.find((x) => x.key === 'EducationLevel') === undefined)
    ) {
      dispatch(
        lightBoxActions.setSecondContent(
          <ModalContainer
            buttonText={t('im-ready')}
            onConfirm={() => {
              verifyHaveUserBasicInfo(userPrefs, periodMissionConfigurationId);
              dispatch(lightBoxActions.setSecondVisibility(false));
            }}
            onClose={() => {
              dispatch(lightBoxActions.setSecondVisibility(false));
            }}
          >
            <View style={{ flexShrink: 1, alignItems: 'center' }}>
              <Image style={{ width: 116, height: 68 }} source={require('../../../../assets/annoucement.png')} />
              <Label style={{ fontSize: 26, fontWeight: '600', color: theme.text.sea, textAlign: 'center' }}>{t('Attention')}</Label>
              <Label style={{ fontSize: 18, fontWeight: '600', color: theme.text.darkTurquoiseTwo, textAlign: 'center' }}>
                {t('We-need-some-basic-information-before-getting-star')}
              </Label>
              <Label style={{ marginTop: 12, fontSize: 14, textAlign: 'center' }}>{t('Please-complete-your-profile-by-answering-the-foll')}</Label>
            </View>
          </ModalContainer>
        )
      );
      dispatch(lightBoxActions.setSecondVisibility(true));
    } else {
      // if (!authStore.multifactor.signInSecondFactor) {
      //   //sharedPopup.showAccountVerificationPopup();
      // } else {
      handleAcceptMission(periodMissionConfigurationId, missionFromMap.branchId);
      // }
    }
  };

  const showExplanationPopup = (dto: GetMysteryShopperProfileMemberDto, onConfirm: any) => {
    dispatch(
      lightBoxActions.setContent(
        <ModalContainer buttonText={t('im-ready')} onConfirm={() => handleOnConfirm(dto, onConfirm)} onClose={handleOnExplanationClose}>
          <View style={{ flexShrink: 1, alignItems: 'center' }}>
            <Image style={{ width: 116, height: 68 }} source={require('../../../../assets/annoucement.png')} />
            <Label style={{ fontSize: 26, fontWeight: '600', color: theme.text.sea, textAlign: 'center' }}>{t('Attention')}</Label>
            <Label style={{ fontSize: 18, fontWeight: '600', color: theme.text.darkTurquoiseTwo, textAlign: 'center' }}>
              {t('Some-missions-require-a-specific-profile')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 14, textAlign: 'center' }}>{t('Please-complete-your-profile-by-answering-the-foll')}</Label>
          </View>
        </ModalContainer>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const handleAcceptMission = (periodMissionConfigurationId, branchId) => {
    let data = acceptMissionViewModels.find((x) => x.periodMissionConfigurationId === periodMissionConfigurationId);
    if (data?.acceptMissionStatus === AcceptMissionStatus.MissionEligible) {
      if (missionDetails?.filter((x) => x.dto.acceptMissionStatus === AcceptMissionStatus.MissionEligible).length > 1) {
        showComboPopup(periodMissionConfigurationId);
      } else {
        acceptMission.mutate({ branchId: branchId, periodMissionConfigurationId: periodMissionConfigurationId });
      }
    } else {
      setLoading(false);

      if (authStore.logged === LoggedState.Anonymous) {
        dispatch(
          lightBoxActions.setContent(
            <LoginPopup
              onLogin={() => {
                dispatch(lightBoxActions.setVisibility(false));
                navigation.navigate('logout' as never);
              }}
              onSignUp={() => {
                dispatch(lightBoxActions.setVisibility(false));
                navigation.navigate({ name: 'logout', params: { signUp: true } } as never);
              }}
            />
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      } else {
        setShowAnonymousPopup(<></>);
        if (data.acceptMissionStatus === AcceptMissionStatus.IncompleteProfileRequirements) {
          if (data.missingProfileMembers[0]) {
            if (advertisementTracking.showAdvertisement('ProfileRequirement')) {
              showExplanationPopup(data.missingProfileMembers[0], () => {
                acceptMission.mutate({ branchId: branchId, periodMissionConfigurationId: periodMissionConfigurationId });
              });
            } else {
              profileManager.current.showProfileMemberPopup(
                {
                  value: '',
                  profileMember: data.missingProfileMembers[0],
                },
                () => {
                  acceptMission.mutate({ branchId: branchId, periodMissionConfigurationId: periodMissionConfigurationId });
                }
              );
            }
          }
        }

        if (data.acceptMissionStatus === AcceptMissionStatus.UserIneligibleForMission) {
          dispatch(
            lightBoxActions.setContent(
              <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('UserIneligibleForMission')}</Label>
              </View>
            )
          );
          dispatch(lightBoxActions.setVisibility(true));
        }

        if (data.acceptMissionStatus === AcceptMissionStatus.MissionAlreadyInProgress) {
          dispatch(
            lightBoxActions.setContent(
              <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('MissionAlreadyInProgress')}</Label>
              </View>
            )
          );
          dispatch(lightBoxActions.setVisibility(true));
        }

        if (data.acceptMissionStatus === AcceptMissionStatus.MissionUnavailable) {
          dispatch(
            lightBoxActions.setContent(
              <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('MissionUnavailable')}</Label>
              </View>
            )
          );
          dispatch(lightBoxActions.setVisibility(true));
        }

        if (data.acceptMissionStatus === AcceptMissionStatus.MissionLimitReached) {
          dispatch(
            lightBoxActions.setContent(
              <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('MissionLimitReached')}</Label>
              </View>
            )
          );
          dispatch(lightBoxActions.setVisibility(true));
        }

        if (data.acceptMissionStatus === AcceptMissionStatus.UserNotAuthenticated) {
          dispatch(
            lightBoxActions.setContent(
              <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Label style={{ fontSize: 16, fontWeight: '600' }}>{t('UserNotAuthenticated')}</Label>
              </View>
            )
          );
          dispatch(lightBoxActions.setVisibility(true));
        }
      }
    }
  };

  const buttonIsDisabled = (status: AcceptMissionStatus) => {
    return (
      authStore.logged !== LoggedState.Anonymous && status != AcceptMissionStatus.MissionEligible && status != AcceptMissionStatus.IncompleteProfileRequirements
    );
  };

  const showComboPopup = (periodMissionConfigurationId: string) => {
    dispatch(
      lightBoxActions.setContent(
        <ComboPopup
          onClose={() => {
            dispatch(lightBoxActions.setOnClose(undefined));
            dispatch(lightBoxActions.setVisibility(false));
          }}
          onConfirm={(missions) => {
            dispatch(lightBoxActions.setIsLoading(true));
            if (missions.length > 0) {
              setAcceptMissionQueue(missions);
              acceptMission.mutate({ branchId: missionFromMap.branchId, periodMissionConfigurationId: missions[0].periodMissionConfigurationId });
            }
          }}
          missions={missionDetails
            .filter((x) => x.dto.acceptMissionStatus === AcceptMissionStatus.MissionEligible)
            .map((x) => {
              let item: ComboMissionItem = {
                dto: x.dto,
                selected: x.dto.periodMissionConfigurationId === periodMissionConfigurationId,
                branchName: missionFromMap?.name,
              };
              return item;
            })}
        />
      )
    );
    dispatch(
      lightBoxActions.setOnClose(() => {
        dispatch(lightBoxActions.setOnClose(undefined));
      })
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const missionStatusText = (status: AcceptMissionStatus) => {
    switch (status) {
      case AcceptMissionStatus.MissionLimitReached:
        return t('MissionLimitReached');
      case AcceptMissionStatus.MissionAlreadyInProgress:
        return t('MissionAlreadyInProgress');
      case AcceptMissionStatus.MissionLimitRotationReach:
        return t('MissionLimitRotationReach');
      case AcceptMissionStatus.MissionUnavailable:
        return t('MissionUnavailable');
      case AcceptMissionStatus.UserIneligibleForMission:
        return t('UserIneligibleForMission');
      case AcceptMissionStatus.UserNotAuthenticated:
        return t('UserNotAuthenticated');
      case AcceptMissionStatus.Retry:
        return t('AcceptMissionRetry');
    }

    return undefined;
  };

  const openMapsWithDirections = (name, address) => {
    const scheme = Platform.OS === 'ios' ? 'maps://app?daddr=' : Platform.OS === 'web' ? 'https://www.google.com/maps/search/?api=1&query=' : 'geo:0,0?q=';
    const query = `${name},${address}`;
    const url = Platform.OS === 'ios' ? `${scheme}${query}` : `${scheme}${query}`;

    Linking.openURL(url);
  };

  return (
    <ScrollView style={styles.missionsBranchDetail.container}>
      <Modal animationType="none" visible={showVerificationPopup} transparent={true}>
        <Overlay showOverLay={true} zIndex={3000}>
          <Popup>
            <AccountVerificationPopup
              onClose={() => {
                setShowVerificationPopup(false);
              }}
            />
          </Popup>
        </Overlay>
      </Modal>
      {Platform.OS === 'web' && (
        <View style={styles.missionsBranchDetail.backButton}>
          <NavigationButton
            icon={faCircleChevronLeft}
            iconSize={24}
            color={theme.text.titleLight}
            labelVariant={LabelType.titleSmall}
            onPress={handleOnBackPress}
            text={t('back')}
          />
        </View>
      )}
      {!loading && (
        <>
          {missionFromMap && (
            <View style={styles.missionsBranchDetail.content.body}>
              {showAnonymousPopup}
              <View style={stylesCard.cardBase}>
                <View style={stylesCard.header.logoBase}>{logo}</View>
                <View style={stylesCard.info}>
                  <View style={stylesCard.missionName}>
                    <Label variant={LabelType.title}>{`${missionFromMap?.name}`}</Label>
                    {missionFromMap.isHidden && <FontAwesomeIcon icon={faEyeSlash} style={stylesCard.missionHiddenIcon} />}
                  </View>
                  {address}
                  <Pressable
                    style={{
                      flexDirection: 'row',
                      gap: 4,
                      alignItems: 'center',
                      borderRadius: 20,
                    }}
                    onPress={() => {
                      openMapsWithDirections(missionFromMap.name, missionFromMap.address);
                    }}
                  >
                    <FontAwesomeIcon icon={faMap} color={theme.text.darkTurquoiseTwo.toString()} size={16} />
                    <Label style={{ fontSize: 11, color: theme.text.darkTurquoiseTwo, fontWeight: '500' }}>{t('Get-directions')}</Label>
                  </Pressable>
                  <>
                    {!!missionFromMap.phone && (
                      <Label style={{ fontSize: 11, color: theme.text.darkTurquoiseTwo, fontWeight: '500' }}>{missionFromMap.phone}</Label>
                    )}
                  </>
                  <View style={stylesCard.preTitle}>
                    <Label variant={LabelType.caption}>{missionFromMap?.tags === '' ? 'N/A' : missionFromMap?.tags}</Label>
                    <Label style={{ flexShrink: 0 }} variant={LabelType.caption}>{`${(missionFromMap.distance / 1000.0).toFixed(2)}km`}</Label>
                  </View>

                  {/* <Pressable
                    style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
                    onPress={() => {
                      openMapsWithDirections(missionFromMap.name, missionFromMap.address);
                    }}
                  >
                    <FontAwesomeIcon icon={faMap} size={16} />
                    <Label style={{ flexShrink: 1 }}>{address}</Label>
                  </Pressable> */}
                </View>

                <MoreInfoButton title={t('help_support')}>{`${t('help.make-sure-you-have-selected-the-correct-branch')}`}</MoreInfoButton>
              </View>
              {false && (
                <View style={styles.missionsBranchDetail.content.missionsCountBase}>
                  <View style={styles.missionsBranchDetail.content.missionsCount.icons}>
                    <Image source={pinSmallBlue} style={styles.missionsBranchDetail.content.missionsCount.icon} />
                    <Image source={pinSmallGreen} style={styles.missionsBranchDetail.content.missionsCount.icon} />
                    <Image source={pinSmallOrange} style={styles.missionsBranchDetail.content.missionsCount.icon} />
                  </View>
                  <Label variant={LabelType.caption} style={styles.missionsBranchDetail.content.missionsCount.label}>
                    {missionDetails?.length ?? 0} {t('missions-for-this-point-of-sale')}
                  </Label>
                </View>
              )}
              {missionDetails ? (
                <>
                  {missionDetails.map((missionDetail) => {
                    return (
                      <View
                        key={missionDetail.dto.periodMissionConfigurationId}
                        style={[styles.missionsBranchDetail.content.infoContainerBase, { paddingBottom: missionDetail.expanded ? 12 : 0 }]}
                      >
                        <Pressable
                          style={styles.missionsBranchDetail.content.infoContainer.title}
                          onPress={(e) => {
                            setMissionDetails((prev) => {
                              let detail = prev.find((x) => x.dto.periodMissionConfigurationId === missionDetail.dto.periodMissionConfigurationId);
                              detail.expanded = !detail.expanded;
                              return prev.slice();
                            });
                          }}
                        >
                          {!buttonIsDisabled(missionDetail.dto.acceptMissionStatus) && (
                            <Image source={pinSmallOrange} style={styles.missionsBranchDetail.content.infoContainer.icon} />
                          )}
                          {buttonIsDisabled(missionDetail.dto.acceptMissionStatus) && (
                            <Image source={pinSmallGrey} style={styles.missionsBranchDetail.content.infoContainer.icon} />
                          )}
                          <Label style={{ fontSize: 15, fontWeight: '700', flexShrink: 1, flexGrow: 1 }} numberOfLines={1}>
                            {missionDetail.dto.missionConfigurationName}
                            {/* <FontAwesomeIcon icon={faCheck} color={theme.text.titleDark.toString()} style={{ marginLeft: 8 }} /> */}
                          </Label>
                          {missionDetail.dto.isTaken && (
                            <Pressable
                              style={{ flexDirection: 'row', alignItems: 'center', marginRight: 4 }}
                              onPress={() => {
                                dispatch(drawerActions.resetState());
                                navigation.navigate({ name: 'mymissions', params: { id: missionDetail.dto.takenMissionAssignationId } } as never);
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} color={theme.text.titleDark.toString()} size={12} />
                              <Label variant={LabelType.caption} style={{ ...StylesHelper.padding(4, 4, 4, 2), color: theme.text.titleDark.toString() }}>
                                {t('Assigned')}
                              </Label>
                            </Pressable>
                          )}
                          <FontAwesomeIcon icon={missionDetail.expanded ? faChevronUp : faChevronDown} style={{ marginLeft: 8 }} />
                        </Pressable>
                        {missionDetail.expanded && (
                          <>
                            {missionDetail.dto.supportedCultureCodes?.length > 0 && (
                              <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16, paddingBottom: 16 }}>
                                <Label style={{ fontSize: 11, color: theme.text.error, fontWeight: '500' }}>
                                  {t('Mission-in')} {missionDetail.dto.supportedCultureCodes?.map((x) => t(x)).join(', ')} {t('only')}!
                                </Label>
                                <MoreInfoButton style={{ marginLeft: 8 }} title={''} color={theme.text.primary.toString()} size={12}>
                                  {t('help.mission-in-language-only').format(missionDetail.dto.supportedCultureCodes?.map((x) => t(x)).join(', '))}
                                </MoreInfoButton>
                              </View>
                            )}
                            <View style={styles.missionsBranchDetail.content.infoContainer.summary}>
                              {false && missionDetail.dto.tags != undefined && missionDetail.dto.tags != '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.summaryItem}>
                                  <FontAwesomeIcon
                                    icon={faStore}
                                    color={theme.text.primary.toString()}
                                    size={20}
                                    style={styles.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                  />
                                  <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                    {missionDetail.dto.tags}
                                  </Label>
                                </View>
                              )}

                              <View style={styles.missionsBranchDetail.content.infoContainer.summaryItem}>
                                <FontAwesomeIcon
                                  icon={faAward}
                                  color={theme.text.primary.toString()}
                                  style={styles.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                  size={20}
                                />
                                <Label variant={LabelType.valueLargeMission} style={{ marginLeft: 6, minWidth: 50 }}>
                                  {userPrefs.uiLanguage === 'en' ? `$${missionDetail.dto.salaryAmount ?? 0}` : `${missionDetail.dto.salaryAmount ?? 0} $`}
                                </Label>
                                <View style={styles.missionsBranchDetail.content.infoContainer.summaryItemValueSecondary}>
                                  <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                    {t('salary')}
                                  </Label>
                                  <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                    ({missionDetail.dto.salaryPoints ?? 0} pts)
                                  </Label>
                                </View>
                                <MoreInfoButton style={{ alignSelf: 'flex-start', marginLeft: 8 }} title={''} openURL={'rewards-link-lanla-website-support'}>
                                  {`${t('help.points-rate')}`}
                                </MoreInfoButton>
                              </View>

                              {missionDetail.dto.requiredPurchase && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.summaryItem}>
                                  <FontAwesomeIcon
                                    icon={faBasketShopping}
                                    color={theme.text.primary.toString()}
                                    style={styles.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                    size={20}
                                  />
                                  <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                    {t('purchase_required')}
                                  </Label>
                                </View>
                              )}
                              {missionDetail.dto.reimbursementValue != undefined && missionDetail.dto.reimbursementValue != '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.summaryItem}>
                                  <FontAwesomeIcon
                                    icon={faReceipt}
                                    color={theme.text.primary.toString()}
                                    style={styles.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                    size={20}
                                  />
                                  <View style={styles.missionsBranchDetail.content.infoContainer.summaryItemValue}>
                                    <Label variant={LabelType.valueLargeMission} style={{ marginLeft: 6, minWidth: 50 }}>
                                      {`${missionDetail.dto.reimbursementValue}`}
                                    </Label>
                                    <View style={styles.missionsBranchDetail.content.infoContainer.summaryItemValueSecondary}>
                                      <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                        {`${t('reimbursement-up-to')} ${missionDetail.dto.reimbursementValue}`}
                                      </Label>
                                      {missionDetail.dto.reimbursementMaxAmount > 0 && (
                                        <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                          Max {missionDetail.dto.reimbursementMaxAmount} $ ({missionDetail.dto.reimbursementMaxPoints} pts)
                                        </Label>
                                      )}
                                    </View>
                                  </View>
                                  <MoreInfoButton style={{ alignSelf: 'flex-start', marginLeft: 8 }} title={''}>
                                    {missionDetail.dto.reimbursementValue.includes('$') ? t('help.points-rate-dollars') : t('help.points-rate-percentage')}
                                  </MoreInfoButton>
                                </View>
                              )}
                              {missionDetail.dto.time && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.summaryItem}>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    color={theme.text.primary.toString()}
                                    style={styles.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                    size={20}
                                  />
                                  <View style={{ flexDirection: 'column' }}>
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700', flexShrink: 1 }} numberOfLines={10}>
                                      {missionDetail.dto.time}
                                    </Label>
                                    {missionDetail.dto.storeLocatorLink != undefined && (
                                      <Pressable
                                        onPress={() => {
                                          Linking.openURL(missionDetail.dto.storeLocatorLink);
                                        }}
                                      >
                                        <Label style={{ fontSize: 13, color: theme.text.error, marginLeft: 6, flexShrink: 1, textDecorationLine: 'underline' }}>
                                          {t('Store-locator-link-text')}
                                        </Label>
                                      </Pressable>
                                    )}
                                  </View>
                                </View>
                              )}
                            </View>
                            {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Objective) &&
                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Objective)?.text !== '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocksBase}>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.title}>
                                    <FontAwesomeIcon icon={faBullseye} style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.titleIcon} />
                                    <Label variant={LabelType.titleDark}>{t('Your-objective')}</Label>
                                    <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                                      {`${t('help.your-objective')}`}
                                    </MoreInfoButton>
                                  </View>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.content}>
                                    <CustomMarkdown>
                                      {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Objective)?.text}
                                    </CustomMarkdown>
                                  </View>
                                </View>
                              )}
                            {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.ToNote) &&
                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.ToNote)?.text !== '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocksBase}>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.title}>
                                    <FontAwesomeIcon icon={faPenNib} style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.titleIcon} />
                                    <Label variant={LabelType.titleDark}>{t('Please-note')}</Label>
                                    <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                                      {`${t('help.please-note')}`}
                                    </MoreInfoButton>
                                  </View>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.content}>
                                    <CustomMarkdown>
                                      {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.ToNote)?.text}
                                    </CustomMarkdown>
                                  </View>
                                </View>
                              )}
                            {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow) &&
                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow)?.text !== '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocksBase}>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.title}>
                                    <FontAwesomeIcon
                                      icon={faTriangleExclamation}
                                      style={{ ...styles.missionsBranchDetail.content.infoContainer.infoBlocks.titleIcon, width: 24, height: 24 }}
                                    />
                                    <Label variant={LabelType.titleDark}>{t('Pay-attention')}</Label>
                                    <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                                      {`${t('help.nice-to-know')}`}
                                    </MoreInfoButton>
                                  </View>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.content}>
                                    <CustomMarkdown>
                                      {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow)?.text}
                                    </CustomMarkdown>
                                  </View>
                                </View>
                              )}
                            {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Scenario) &&
                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text !== '' && (
                                <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocksBase}>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.title}>
                                    <FontAwesomeIcon icon={faUserSecret} style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.titleIcon} />
                                    <Label variant={LabelType.titleDark}>{t('The-scenario')}</Label>
                                    <MoreInfoButton style={{ marginLeft: 8 }} title={''} openURL={'the-scenario-lanla-website-support-link'}>
                                      {t('help.the-scenario')}
                                    </MoreInfoButton>
                                  </View>
                                  <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.content}>
                                    <CustomMarkdown>
                                      {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text}
                                    </CustomMarkdown>
                                  </View>
                                </View>
                              )}
                            <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.buttonContainer}>
                              {missionStatusText(
                                authStore.logged !== LoggedState.Anonymous &&
                                  acceptMissionViewModels?.find((x) => x.periodMissionConfigurationId === missionDetail.dto.periodMissionConfigurationId)
                                    ?.acceptMissionStatus
                              ) && (
                                <View
                                  style={{
                                    paddingHorizontal: 16,
                                    width: '100%',
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      gap: 16,
                                      alignItems: 'center',
                                      backgroundColor: theme.colorErrorPrimaryBackground,
                                      ...StylesHelper.padding(12, 16),
                                      ...StylesHelper.borderRadius(8),
                                      flexGrow: 1,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ flexShrink: 0 }}
                                      icon={faCircleExclamation}
                                      size={28}
                                      color={theme.colorErrorPrimary.toString()}
                                    />
                                    <View style={{ flexShrink: 1 }}>
                                      {/* <Label style={{ fontSize: 14, color: theme.colorErrorPrimary, fontWeight: '600' }}>{t('This-mission-is-not-accessible')}</Label> */}
                                      <Label style={{ color: theme.colorErrorPrimary, flexShrink: 1 }} numberOfLines={5}>
                                        {missionStatusText(
                                          acceptMissionViewModels?.find(
                                            (x) => x.periodMissionConfigurationId === missionDetail.dto.periodMissionConfigurationId
                                          )?.acceptMissionStatus
                                        )}
                                      </Label>
                                    </View>
                                  </View>
                                </View>
                              )}
                              {!buttonIsDisabled(
                                acceptMissionViewModels?.find((x) => x.periodMissionConfigurationId === missionDetail.dto.periodMissionConfigurationId)
                                  ?.acceptMissionStatus
                              ) && (
                                <View style={{ flexDirection: 'row' }}>
                                  <CustomButton
                                    disabled={acceptLoading}
                                    textStyle={styles.missionsBranchDetail.content.infoContainer.infoBlocks.button}
                                    title={t('accept_the_mission')}
                                    onPress={() => {
                                      needToKnowMoreAboutUserPopup(missionDetail.dto.periodMissionConfigurationId);
                                    }}
                                  />
                                </View>
                              )}
                            </View>
                            {((missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != undefined &&
                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != '') ||
                              (missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != undefined &&
                                missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != '')) && (
                              <View style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.baseLinks}>
                                {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != undefined &&
                                  missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != '' && (
                                    <Pressable
                                      style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.link}
                                      onPress={() =>
                                        props.onConditionsPress
                                          ? props.onConditionsPress(
                                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text
                                            )
                                          : false
                                      }
                                    >
                                      <Label variant={LabelType.caption} style={{ textTransform: 'lowercase' }}>
                                        {t('conditions')}
                                      </Label>
                                    </Pressable>
                                  )}
                                {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != undefined &&
                                  missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != '' &&
                                  missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != undefined &&
                                  missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != '' && (
                                    <Label variant={LabelType.caption}>&nbsp;{t('and')}&nbsp;</Label>
                                  )}
                                {missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != undefined &&
                                  missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != '' && (
                                    <Pressable
                                      style={styles.missionsBranchDetail.content.infoContainer.infoBlocks.link}
                                      onPress={() =>
                                        props.onAdmissibilityPress
                                          ? props.onAdmissibilityPress(
                                              missionDetail.dto.details.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text
                                            )
                                          : false
                                      }
                                    >
                                      <Label variant={LabelType.caption}>{t('Admissibility')}</Label>
                                    </Pressable>
                                  )}
                              </View>
                            )}
                          </>
                        )}
                      </View>
                    );
                  })}
                </>
              ) : (
                <View style={{ flexGrow: 1, marginBottom: 24, marginTop: 12, paddingBottom: 12, height: 100 }}>
                  <Loading />
                </View>
              )}
            </View>
          )}
        </>
      )}
      {loading && <Loading />}
      <ProfileManager ref={profileManager} />
    </ScrollView>
  );
};

export default MissionBranchList;
