import React, { useState } from 'react';
import { Linking, Pressable, View } from 'react-native';
import { MysteryShopperClient } from '../../../../api/api.g';
import useApi from '../../../../hooks/api-hook';
import { useAppSelector } from '../../../../hooks/store-hook';
import useCustomTheme from '../../../../hooks/theme-hook';
import useTranslation from '../../../../hooks/translation-hook';
import CustomTextInput, { ValidationResult, InputTypeValidation } from '../../../common/CustomTextInput';
import Label from '../../../common/Label';
import { useMutation } from '@tanstack/react-query';
import { UserPrefsHelper } from '../../../../models/helpers/userPrefsHelper';
import ProfileModal from '../ProfileModal';
import Popup from '../../../common/Popup';
import useUserPrefsService from '../../../../hooks/userPrefsService-hook';

const PhonePopup = (props) => {
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const theme = useCustomTheme();
  const userPrefService = useUserPrefsService();
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const [userPrefsValue, setUserPrefsValue] = useState(userPrefs);
  const [validation, setValidation] = useState<ValidationResult>();
  const [forceValidation, setForceValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveBasicMutation = useMutation({
    mutationFn: () => {
      const data = UserPrefsHelper.mapToSaveBasicInformationDto(userPrefsValue);
      return apiMysteryShopper.saveBasicInformation(data).then(() => {
        userPrefService.updateUserProfileInfo((userPrefs) => {
          setIsLoading(false);
          props.onClose && props.onClose();
          props.onConfirm && props.onConfirm(userPrefs);
        });
      });
    },
  });

  const verifyPhoneNumber = useMutation({
    mutationFn: (phoneNumber: string) => {
      setIsLoading(true);
      return apiMysteryShopper
        .verifyPhoneNumber(phoneNumber, apiMysteryShopper.tokenSource?.token)
        .then((response) => {
          if (response) {
            setIsLoading(false);
            setValidation({ valid: false, message: t('PhoneNumber-used') });
          } else {
            saveBasicMutation.mutate();
          }
        })
        .catch(() => {
          setIsLoading(false);
          setValidation({ valid: false, message: "Error: Couldn't verify phone number." });
        });
    },
  });

  const handleOnConfirm = () => {
    setValidation(undefined);
    if (userPrefsValue.phoneNumber) {
      verifyPhoneNumber.mutate(userPrefsValue.phoneNumber);
    } else {
      setForceValidation(true);
    }
  };

  const handleOpenContactLink = () => {
    const url = 'https://lanla.com/#contact';
    Linking.openURL(url).catch((err) => console.error('Failed to open URL:', err));
  };

  return (
    <Popup loading={isLoading}>
      <ProfileModal
        title={t('My-informations')}
        subtitle={t('what_is_your_cellphone_number')}
        height={props.height}
        pageNumber={props.currentPage}
        maxPageNumber={props.maxPage}
        onClose={() => {
          props.onClose && props.onClose();
        }}
        onConfirm={() => {
          handleOnConfirm();
        }}
      >
        <View>
          <Label style={{ fontSize: 13, maxWidth: 250, fontStyle: 'italic', marginTop: -8 }} numberOfLines={10}>
            {t('We-will-need-it-in-case-that-we-will-contact-you-a')}
          </Label>
          <Label style={{ fontWeight: '600', maxWidth: 250, marginTop: 8 }} numberOfLines={5}>
            {t('Please-be-sure-to-use-a-SMS-enable-phone-number-If')}
          </Label>
          <Pressable style={{ marginBottom: 8 }} onPress={handleOpenContactLink}>
            <Label style={{ color: theme.text.sea }}>{t('contact-us-link')}</Label>
          </Pressable>
          <CustomTextInput
            forceValidation={forceValidation}
            placeholder={t('your_cellphone_number')}
            required
            minLength={14}
            maxLength={14}
            validateOnBlur
            value={userPrefsValue.phoneNumber ? userPrefsValue.phoneNumber : undefined}
            inputTypeValidation={InputTypeValidation.phone}
            onChangeText={(t) => {
              setUserPrefsValue((current) => {
                return { ...current, phoneNumber: t };
              });
            }}
            onValidationChange={(validation) => {
              setForceValidation(false);
              setValidation(validation);
            }}
          />
          {validation?.valid === false && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{validation.message}</Label>}
        </View>
      </ProfileModal>
    </Popup>
  );
};

export default PhonePopup;
